<template>
  <ModalWrapper
    :is-show="isShowModal"
    class="dialog"
    @update:isShow="updateShowModal($event)"
  >
    <v-progress-circular
      v-if="!battle"
      class="loader"
      color="#FC7800"
      indeterminate
      size="200"
      width="15"
    />
    <v-card
      v-else
      class="dialog__card"
      outlined
    >
      <div class="dialog__content">
        <h3 class="dialog__title">Battle details</h3>
        <BKOrganizationSelect
          :orgs="getUserDB.organizations"
          :selected-org.sync="battle.orgId"
          class="my-4"
        />
        <p class="dialog__subtitle">Category type</p>
        <v-text-field
          v-model="battle.title"
          autocomplete="off"
          background-color="var(--color-white)"
          class="dialog__text-field"
          label="Title"
          outlined
        />
        <v-select
          v-model="battle.crewSize"
          :items="categories"
          class="dialog__select"
          outlined
        />
        <VueEditor
          v-model="battle.description"
          :editor-toolbar="editorConfig"
          class="editor"
          label="Give a description of the battle (optional)"
        />
        <CheckboxInput
          v-model="battle.hideParticipants"
          :model-value="battle.hideParticipants"
          class="hide-participants-checkbox"
        >
          <template #label> Hide competitors and crews until day of event</template>
        </CheckboxInput>
        <div>
          <p class="description">Restrict how many competitors register OR check in</p>
          <div class="checkbox-container">
            <CheckboxInput
              :disabled="battle.hideParticipants"
              :model-value="battle.participationCapType === 'registration'"
              @change="handleCapTypeSelecting('registration')"
            >
              <template #label>Cap registration</template>
            </CheckboxInput>

            <CheckboxInput
              :model-value="battle.participationCapType === 'checkIn'"
              @change="handleCapTypeSelecting('checkIn')"
            >
              <template #label>Cap check-in</template>
            </CheckboxInput>
          </div>
        </div>

        <transition name="slide-fade">
          <v-text-field
            v-if="battle.participationCapType !== 'none'"
            v-model.number="battle.limit"
            autocomplete="off"
            background-color="var(--color-white)"
            class="dialog__text-field"
            label="Enter number"
            min="0"
            outlined
            type="number"
          />
        </transition>

        <p class="dialog__subtitle">Judging system</p>

        <v-select
          v-model="battle.style"
          :items="battleStyles"
          class="dialog__select"
          label="Select judging system"
          outlined
        />

        <template v-if="battle.style !== 'classic'">
          <span>Bracket size</span>
          <v-select
            v-model="battle.bracketSize"
            :items="bracketSizes"
            class="dialog__select"
            outlined
            @change="setLevels"
          />

          <span>Rounds</span>
          <v-select
            v-for="level in battle.bracketLevels"
            :key="level.name"
            v-model="level.rounds"
            :items="availableRounds"
            :label="level.name"
            class="dialog__select"
            outlined
          />
        </template>

        <p class="dialog__subtitle">Judging</p>

        <BKUserSelect
          :role="`judge`"
          :select-user-chip.sync="battle.judges"
          label-text="Find and add judges"
          @removeUser="removeBattleRole"
          @selectUser="addBattleRole"
        />
        <p class="dialog__subtitle">Prizes</p>
        <v-text-field
          v-model="battle.prizesDesc"
          autocomplete="off"
          background-color="var(--color-white)"
          class="dialog__text-field"
          label="Prizes descriptions if needed"
          outlined
        />
        <div
          v-for="(place, i) in battle.prizes"
          :key="i"
          class="dialog__place my-2 d-flex align-center justify-center"
        >
          <div class="dialog__wrap-place text-center flex-shrink-0">
            <v-icon
              v-if="i === 0 || i === 1"
              :class="i === 0 ? 'amber--text' : 'grey--text'"
            >
              {{ mdiTrophyOutline }}
            </v-icon>
            <span class="mx-2">{{ place.title }}</span>
          </div>
          <v-text-field
            v-model.number="place.amount"
            class="mb-n8 dialog__amount"
            label="Cash Prize"
            min="0"
            outlined
            prefix="$"
            type="number"
            @input="setPlacePrize(place.id)"
          />
        </div>
        <div class="dialog__place my-2 d-flex align-center justify-end">
          <v-btn
            v-if="isShowBtnAddPlace"
            class="dialog__btn-add mr-auto"
            color="var(--color-blue-800)"
            text
            tile
            @click.stop="addPlaceToBattle"
          >
            Add Place
            <v-icon class>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="battle.prizes.length && battle.prizes.length !== 1"
            class="dialog__btn-remove"
            color="red"
            icon
            text
            @click.stop="handlerClickDeletePlace"
          >
            <v-icon>
              {{ mdiMinusCircleOutline }}
            </v-icon>
          </v-btn>
        </div>
        <p
          v-show="error"
          class="red--text text-center"
        >
          {{ error }}
        </p>
        <v-card-actions>
          <v-btn
            v-if="isEditMode"
            :disabled="createBtnLoading"
            :loading="createBtnLoading"
            class="dialog__btn-create px-8 mt-10 mx-auto"
            color="var(--color-blue-800)"
            height="45"
            outlined
            tile
            @click.stop="updateBattle"
          >
            Publish Battle
          </v-btn>
          <v-btn
            v-else
            :disabled="createBtnLoading"
            :loading="createBtnLoading"
            class="dialog__btn-create px-8 mt-10 mx-auto"
            color="var(--color-blue-800)"
            height="45"
            outlined
            tile
            @click.stop="updateBattle"
          >
            Create Battle
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import api from '@/api'
import { mdiMinusCircleOutline, mdiPlus, mdiTrophyOutline } from '@mdi/js'

import BKUserSelect from '@/components/BKUserSelect.vue'
import { ModalWrapper } from '@/shared/ui/modals'
import BKOrganizationSelect from '@/components/BKOrganizationSelect.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDateFormattedForPicker } from '@/services/date'
import { VueEditor } from 'vue2-editor'
import { BattleDB, BracketLevel, BracketLevelName, CapTypeBattle, useBattle } from '@/enitites/battle'
import { computed, onMounted, ref, watch } from 'vue'
import { UserDB } from '@/enitites/user'
import { CheckboxInput } from '@/shared/ui/inputs'
import BKEditorConfig from '../../../common/vue2Config'

const { battleCategories, battlePlaces } = useBattle()
const props = defineProps<{
  isShowModal: boolean
  isEditMode: boolean
  battleId: number
}>()

const emit = defineEmits<{
  (e: 'update:isShowModal', value: boolean): void
  (e: 'updateBattle', value: BattleDB): void
}>()

const battle = ref<null | BattleDB>(null)
const categories = battleCategories
const existingPlaces = battlePlaces
const createBtnLoading = ref(false)
const battleStyles = ['bracket', 'classic']
const error = ref<string | null>(null)
const dateForPicker = ref<string | null>(null)
const bracketSizes = [32, 16, 8, 4, 2]
const availableRounds = Array.from(Array(101).keys()).map((n) => n + 1)
const editorConfig = BKEditorConfig
const getUserDB = computed(() => store.getters.getUserDB)
const isShowBtnAddPlace = computed(() => battle.value?.prizes.length !== existingPlaces.length)

function handleCapTypeSelecting(capType: CapTypeBattle) {
  if (!battle.value) return

  if (battle.value.participationCapType === capType) {
    battle.value.participationCapType = 'none'
    battle.value.limit = 0
  } else {
    battle.value.participationCapType = capType
  }
}

watch(
  () => battle.value?.hideParticipants,
  () => {
    if (!battle.value) return
    if (battle.value.hideParticipants && battle.value.participationCapType === 'registration') {
      battle.value.participationCapType = 'none'
      battle.value.limit = 0
    }
  }
)

async function updateBattle() {
  if (!battle.value || !dateForPicker.value) return
  createBtnLoading.value = true
  battle.value.startDate = new Date(dateForPicker.value.replace(/-/g, '/'))
  const fetchedBattle = await api.battles.update(battle.value)
  if (!fetchedBattle) {
    error.value = 'Something went wrong. Please try again later.'
    createBtnLoading.value = false
    emit('update:isShowModal', false)
    return
  }
  createBtnLoading.value = false
  emit('update:isShowModal', false)
  emit('updateBattle', fetchedBattle)
}

function addPlaceToBattle() {
  if (!battle.value) return
  battle.value.prizes = [
    ...battle.value.prizes,
    {
      id: existingPlaces[battle.value.prizes.length].id,
      title: existingPlaces[battle.value.prizes.length].title,
      amount: 0,
    },
  ]
}

function setLevels() {
  const battleValue = battle.value
  if (!battleValue) return

  const levelsMax = bracketSizes
    .slice()
    .reverse()
    .findIndex((s) => s === battleValue.bracketSize)
  battleValue.bracketLevels = []

  if (battleValue.bracketSize === 2) {
    battleValue.bracketLevels.push({
      name: 'Final',
      rounds: 1,
    })

    return
  }

  for (let i = 0; i < levelsMax; i++) {
    const level: BracketLevel = {
      name:
        i === battleValue.bracketLevels.length - 1
          ? 'Semi-final'
          : (`Top ${bracketSizes.filter((size) => size <= battleValue.bracketSize)[i]}` as BracketLevelName),
      rounds: 1,
    }

    battleValue.bracketLevels.push(level)
  }

  battleValue.bracketLevels.push({
    name: 'Final',
    rounds: 1,
  })
}

function handlerClickDeletePlace() {
  if (!battle.value) return
  battle.value.prizes.pop()
}

function updateShowModal(evt: boolean) {
  emit('update:isShowModal', evt)
}

async function addBattleRole(user: UserDB, role: string) {
  if (!battle.value) return
  const updatedRoles = await api.battles.addRole(battle.value.id, user.id, role)
  if (updatedRoles) battle.value.judges = updatedRoles
}

async function removeBattleRole(user: UserDB, role: string) {
  if (!battle.value) return
  const updatedRoles = await api.battles.removeRole(battle.value.id, user.id, role)
  if (updatedRoles) battle.value.judges = updatedRoles
}

function setPlacePrize(placeId: number) {
  if (!battle.value) return
  const place = battle.value.prizes.find((placeItem) => placeItem.id === placeId)
  if (!place) return
  place.amount = Math.abs(place.amount)
  if (place.amount > 0 && place.amount < 1) place.amount = 1
}

onMounted(async () => {
  battle.value = await api.battles.getBattle(props.battleId)
  if (!battle.value) return
  dateForPicker.value = getDateFormattedForPicker(battle.value.startDate)
  if (!battle.value.prizes.length) addPlaceToBattle()
  if (!battle.value.bracketLevels.length) setLevels()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.loader {
  display: flex !important;
  margin: 210px auto;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.editor {
  margin-bottom: 20px;
}

.wrap {
  max-width: 400px;
  margin: 0 auto;
}

.description {
  @include font-body2;

  margin-bottom: 0;
  color: var(--color-grey-300);
}

.dialog {
  &__close {
    position: absolute;
    z-index: 1;
    width: auto;
    margin-top: -30px;
    text-transform: none;
  }

  &__content {
    padding: 40px 70px;

    @media (max-width: 600px) {
      padding: 100px 20px 20px;
    }
  }

  &__card {
    position: relative;
  }

  &__title {
    @include font-h2;

    text-transform: uppercase;
  }

  &__subtitle {
    margin: 10px 0 8px;
    color: var(--color-dark-800);
  }

  &__text-field {
    margin-bottom: -20px !important;

    :deep(input) {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        appearance: textfield;
      }
    }
  }

  &__select {
    margin-bottom: -20px !important;
  }

  &__place {
    position: relative;
    width: 250px;
  }

  &__wrap-place {
    width: 120px;
  }

  &__btn-create {
    font-weight: bold;
    border: 2px solid;
  }

  &__btn-add {
    font-weight: bold;
  }

  &__btn-remove {
    position: absolute;
    top: -60px;
    right: -50px;
  }
}

.hide-participants-checkbox {
  margin-bottom: 24px;
}

.checkbox-container {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
