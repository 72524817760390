<template>
  <div
    :class="{ highlighted: isHighlighted }"
    class="participant"
  >
    <div class="wrap-avatar">
      <router-link
        :to="{ name: 'Profile', params: { id: participant.id } }"
        class="user-link"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}
        </span>
        <BKBadge
          v-if="isParticipantCheckedIn || !isParticipantConfirmed"
          :color="getBadgeColor"
          bordered
          bottom
          offset-x="13"
        >
          <template #badge>
            <span>
              <v-icon v-if="isParticipantCheckedIn">{{ mdiCheck }}</v-icon>
              <v-icon v-if="!isParticipantConfirmed">{{ mdiHelp }}</v-icon>
            </span>
          </template>
          <v-avatar
            class="d-inline-block"
            size="32"
          >
            <v-img :src="getAvatar(participant.avatar, 64)" />
          </v-avatar>
        </BKBadge>
        <v-avatar
          v-else
          size="32"
        >
          <img
            :alt="participant.dancerName"
            :src="getAvatar(participant.avatar, 64)"
          />
        </v-avatar>
        <span class="participant-name">{{ participant.dancerName }}</span>
      </router-link>

      <v-btn
        v-if="event.permissions.canEdit"
        icon
        type="button"
        @click="isShowActions = !isShowActions"
      >
        <img
          v-if="!isShowActions"
          alt="open"
          src="/img/ic_Menu-Elipses-Horizontal.svg"
        />
        <img
          v-else
          alt="close"
          src="/img/service-worker.svg"
        />
      </v-btn>

      <template v-if="isShowActions">
        <div class="actions-container mt-5">
          <slot name="actions" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Images from '@/mixins/image'
import BKBadge from '@/components/AppShared/BKBadge.vue'
import { mdiCheck, mdiHelp } from '@mdi/js'

export default {
  name: 'BattleParticipantRow',
  components: { BKBadge },
  mixins: [Images],
  props: {
    participant: {
      type: Object,
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiCheck,
      mdiHelp,
      loading: false,
      isShowActions: false,
    }
  },
  computed: {
    getBadgeColor() {
      let color
      if (this.isParticipantCheckedIn) {
        color = 'var(--color-green)'
      }

      if (!this.isParticipantConfirmed) {
        color = 'var(--color-orange-500)'
      }

      return color
    },
    isParticipantConfirmed() {
      return new Date(this.participant.confirmedAt).getFullYear() > 2000
    },
    isParticipantCheckedIn() {
      return new Date(this.participant.checkedInAt).getFullYear() > 2000
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.participant {
  padding: 12px 12px 16px;
  border-bottom: 1px solid var(--color-grey-600);

  &:last-child {
    border-bottom: none;
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }
}

.wrap-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.user-link {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--color-dark-800) !important;
}

.actions-container {
  display: grid;
  flex-basis: 100%;
  gap: 20px;
}
</style>
