<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import BattleParticipantRow from '@/components/Battle/BattleParticipantRow.vue'
import BKInput from '@/components/AppShared/BKInput.vue'
import { BKConfirmationDialog } from '@/shared/ui/modals'
import { mdiMagnify } from '@mdi/js'
import { useThrottleFn } from '@vueuse/core'
import api from '@/api'
import BattleWaitList from '@/components/BattleWaitList.vue'
import { ConfirmationDialog } from '@/types/dialogs'
import { eventApi, EventDB } from '@/enitites/event'
import { DownloadCSVButton } from '@/features/csv'
import { BattleDB, BattleParticipant } from '@/enitites/battle'

const props = defineProps<{
  event: EventDB
  battle: BattleDB<BattleParticipant>
}>()

const emit = defineEmits<{
  (e: 'update:event', updatedEvent: EventDB): void
  (e: 'reload:battle'): void
}>()

const dancersSearch = ref('')
const loading = ref(false)
const confirmationDialog = ref<ConfirmationDialog>({
  isShow: false,
  title: '',
  subtitle: '',
  confirmText: '',
  confirm: undefined,
})

const totalParticipants = computed(() =>
  props.battle.participants.filter((dancer) =>
    dancer.dancerName.toLowerCase().includes(dancersSearch.value.toLowerCase())
  )
)

const waitList = computed(() =>
  props.battle.waitList.filter((dancer) => dancer.dancerName.toLowerCase().includes(dancersSearch.value.toLowerCase()))
)

const participantsCheckedIn = computed(() =>
  props.battle.participants.reduce((acc, dancer) => (dancer.checkedIn ? acc + 1 : acc), 0)
)

const battleLimitText = computed(() => {
  if (props.battle.participationCapType === 'none') return ''
  return props.battle.participationCapType === 'checkIn'
    ? `Check-in cap: ${props.battle.limit}`
    : `Registration cap: ${props.battle.limit}`
})

function getIsParticipantHighlighted(participant: BattleParticipant) {
  return participant.id === props.event.id
}

async function checkInParticipant(participant: BattleParticipant) {
  loading.value = true

  const updatedEvent = await eventApi.checkInUser(props.event.id, participant.id)
  if (updatedEvent) emit('update:event', updatedEvent)
  emit('reload:battle')

  loading.value = false
}

const throttledCheckInParticipant = useThrottleFn(
  (participant: BattleParticipant) => checkInParticipant(participant),
  5000
)

async function unCheckInParticipant(participant: BattleParticipant) {
  loading.value = true

  const data = await api.admin.uncheckInDancer(props.event.id, participant.id)
  if (data) emit('update:event', data)
  emit('reload:battle')

  loading.value = false
}

async function unregisterParticipant(participant: BattleParticipant) {
  loading.value = true

  const data = await api.admin.unregisterDancer(props.battle.id, participant.id)
  if (data) emit('reload:battle')

  loading.value = false
}

function showUnregisterConfirmationDialog(participant: BattleParticipant) {
  confirmationDialog.value = {
    isShow: true,
    title: 'Unregister',
    subtitle: `Are you sure you want to unregister ${participant.dancerName} from Breaking?`,
    confirmText: 'Unregister',
    confirm: () => {
      unregisterParticipant(participant)
      confirmationDialog.value.isShow = false
    },
  }
}

function showUnCheckInConfirmationDialog(participant: BattleParticipant) {
  confirmationDialog.value = {
    isShow: true,
    title: 'Uncheck',
    subtitle: `Are you sure you want to uncheck ${participant.dancerName} from Breaking?`,
    confirmText: 'Uncheck',
    confirm: () => {
      unCheckInParticipant(participant)
      confirmationDialog.value.isShow = false
    },
  }
}
</script>

<template>
  <div class="battle-participants">
    <div class="general-info">
      <DownloadCSVButton
        v-if="battle.canDownload"
        :battle-id="battle.id"
        class="csv-btn"
      />
      <template v-if="!battle.hideParticipants">
        <h3
          v-if="event.period !== 'future'"
          class="dancers-heading"
        >
          {{ participantsCheckedIn }}/{{ battle.participants.length }} Dancers checked in
        </h3>
        <h3
          v-else
          class="dancers-heading"
        >
          {{ battle.participants.length }} Dancers registered
        </h3>
        <p
          v-if="battle.limit || battle.participationCapType !== 'none'"
          class="battle-limit-heading"
        >
          {{ battleLimitText }}
        </p>
      </template>
      <div
        v-if="!battle.hideParticipants"
        class="search-dancers"
      >
        <BKInput
          v-model="dancersSearch"
          :append-icon="mdiMagnify"
          hide-details
          label="Search dancers"
        />
      </div>
    </div>
    <BattleParticipantRow
      v-for="participant in totalParticipants"
      :key="participant.id"
      :battle="battle"
      :comfirmation-dialog="confirmationDialog"
      :event="event"
      :is-highlighted="getIsParticipantHighlighted(participant)"
      :participant="participant"
      class="participant"
      @update:event="$emit('update:event', $event)"
      @reload:battle="$emit('reload:battle')"
    >
      <template #actions>
        <BKButton
          v-if="!participant.checkedIn"
          :loading="loading"
          class="action"
          color="var(--color-green)"
          height="42"
          white-text
          @click="throttledCheckInParticipant(participant)"
        >
          Check-in
        </BKButton>
        <BKButton
          v-if="battle.canUnregister"
          :loading="loading"
          class="action"
          color="var(--color-blue-800)"
          height="42"
          outlined
          @click="showUnregisterConfirmationDialog(participant)"
        >
          Unregister
        </BKButton>
        <BKButton
          v-if="participant.checkedIn"
          :loading="loading"
          class="action"
          color="var(--rasberry-red)"
          height="42"
          white-text
          @click="showUnCheckInConfirmationDialog(participant)"
        >
          Un-check
        </BKButton>
      </template>
    </BattleParticipantRow>
    <BKConfirmationDialog
      :confirm-text="confirmationDialog.confirmText"
      :is-show="confirmationDialog.isShow"
      :title="confirmationDialog.title"
      @cancel="confirmationDialog.isShow = false"
      @confirm="confirmationDialog.confirm?.()"
    >
      {{ confirmationDialog.subtitle }}
    </BKConfirmationDialog>
    <BattleWaitList
      :battle="battle"
      :wait-list-length="waitList.length"
    >
      <template #wait-list>
        <BattleParticipantRow
          v-for="(participant, index) in waitList"
          :key="participant.id + 'registered' + index"
          :battle="battle"
          :comfirmation-dialog="confirmationDialog"
          :event="event"
          :is-highlighted="getIsParticipantHighlighted(participant)"
          :participant="participant"
          class="participant"
          @update:event="$emit('update:event', $event)"
          @reload:battle="$emit('reload:battle')"
        >
          <template #actions>
            <BKButton
              v-if="!participant.checkedIn"
              :loading="loading"
              class="action"
              color="var(--color-green)"
              height="42"
              white-text
              @click="throttledCheckInParticipant(participant)"
            >
              Check-in
            </BKButton>
            <BKButton
              v-if="battle.canUnregister"
              :loading="loading"
              class="action"
              color="var(--color-blue-800)"
              height="42"
              outlined
              @click="showUnregisterConfirmationDialog(participant)"
            >
              Unregister
            </BKButton>
            <BKButton
              v-if="participant.checkedIn"
              :loading="loading"
              class="action"
              color="var(--rasberry-red)"
              height="42"
              white-text
              @click="showUnCheckInConfirmationDialog(participant)"
            >
              Un-check
            </BKButton>
          </template>
        </BattleParticipantRow>
      </template>
    </BattleWaitList>
    <div v-if="battle.hideParticipants">
      <img
        v-for="item in 16"
        :key="item"
        alt="There are no crews"
        src="/img/battle/battle-list-Item-blurred.png"
        style="width: 100%"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.battle-participants {
  position: relative;
  margin: 40px 0;
  background-color: var(--color-grey-100) !important;
}

.general-info {
  padding: 0 20px;
}

.dancers-heading {
  @include font-h3;

  margin-bottom: 8px;
}

.battle-limit-heading {
  margin-bottom: 20px;
}

.search-dancers {
  margin-bottom: 20px;
}

.participant {
  padding: 20px;
}

.csv-btn {
  max-width: 300px;
  margin-bottom: 24px;
}
</style>
