<template>
  <section>
    <BKLoader v-if="loadingPage" />
    <section
      v-else
      class="sparring"
    >
      <section class="wrapper">
        <SparringBreadcrumbs
          :brackets-id="battle.id"
          :title="battle.title"
        />

        <h2
          v-if="isAudienceVoteMode"
          class="audience-mode-title page-title"
        >
          Vote for the people’s champ
        </h2>

        <AudienceVoteView
          v-if="isAudienceVoteMode"
          :sparring="sparring"
          @update:sparring="handleAudienceVote($event)"
        />

        <template v-else>
          <section class="rounds">
            <section class="sparring-header">
              <div>
                <article class="participant">
                  <v-icon
                    v-if="sparring.red && sparring.red.id === sparring.winnerId"
                    class="icon-gold"
                  >
                    {{ mdiCrownOutline }}
                  </v-icon>
                  <div class="name">
                    {{ (sparring.red && sparring.red.name) || 'N/A' }}
                  </div>
                </article>
              </div>

              <div class="empty" />

              <article class="participant">
                <v-icon
                  v-if="sparring.blue && sparring.blue.id === sparring.winnerId"
                  class="icon-gold"
                >
                  {{ mdiCrownOutline }}
                </v-icon>
                <div class="name">
                  {{ (sparring.blue && sparring.blue.name) || 'N/A' }}
                </div>
              </article>
            </section>
            <template v-for="(round, index) in sparring.rounds">
              <SparringRoundScoreEditor
                v-if="getRoundJudgment(index + 1)"
                :key="index"
                :disabled="!isEnabledToJudgment(index + 1)"
                :name="getRoundName(index + 1)"
                :round="getRoundJudgment(index + 1)"
                class="round"
                @savePoints="savePoints"
              />

              <SparringRoundScore
                v-if="!isPointsEmpty && !getRoundJudgment(index + 1)"
                :key="index + 'score'"
                :can-edit="sparring.permissions.canSetScore"
                :name="getRoundName(index + 1)"
                :round="round"
                class="round"
                @edit="setForEdit(index, round)"
              />
            </template>
          </section>

          <section
            v-if="!isAdminOrganizerView && !sparring.winnerId"
            class="total-score"
          >
            <div class="participant-total">
              {{ sparring?.red?.totalPoints || 0 }}
            </div>
            <div class="name">Total</div>
            <div class="participant-total">
              {{ sparring?.blue?.totalPoints || 0 }}
            </div>
          </section>

          <section
            v-if="!isAdminOrganizerView && !sparring.winnerId"
            class="audience-votes"
          >
            <h2>Audience votes</h2>

            <div
              :class="{ winner: audienceWinnerColor === 'red' }"
              class="score"
            >
              <v-icon
                v-if="audienceWinnerColor === 'red'"
                class="icon-gold small"
              >
                {{ mdiCrownOutline }}
              </v-icon>
              <div class="points">
                {{ (sparring.red && sparring.red.audiencePoints) || 0 }}
              </div>
            </div>

            <div class="empty" />

            <div
              :class="{ winner: audienceWinnerColor === 'blue' }"
              class="score"
            >
              <v-icon
                v-if="audienceWinnerColor === 'blue'"
                class="icon-gold small"
              >
                {{ mdiCrownOutline }}
              </v-icon>
              <div class="points">
                {{ (sparring.blue && sparring.blue.audiencePoints) || 0 }}
              </div>
            </div>
          </section>

          <section class="actions">
            <BKButton
              v-if="sparring.permissions.canSetScore && roundsForJudgment.length"
              :loading="loading"
              class="action"
              color="var(--color-blue-800)"
              white-text
              @click="submitScore"
            >
              Submit
            </BKButton>

            <BKButton
              v-if="sparring.permissions.canAddExtra"
              :disabled="!isSubmitScoreEnabled"
              :loading="loading"
              class="action"
              color="var(--color-blue-800)"
              white-text
              @click="addExtraRound"
            >
              Add tie breaker
            </BKButton>

            <BKButton
              v-if="sparring.permissions.canFinish && !isPointsEmpty"
              :loading="loading"
              class="action"
              color="var(--color-blue-800)"
              white-text
              @click="finish"
            >
              Finish
            </BKButton>
            <BKButton
              v-if="isShowResetBtn && !isPointsEmpty"
              :loading="loading"
              class="action"
              color="var(--color-blue-800)"
              white-text
              @click="reset"
            >
              Reset score
            </BKButton>
          </section>
        </template>
      </section>

      <div class="wrapper">
        <SparringAdminView
          v-if="isAdminOrganizerView && !sparring.permissions.canSetScore && !sparring.permissions.canSetAudienceScore"
          :battle="battle"
          :sparring="sparring"
          @update:sparring="sparring = $event"
        />
        <ScorelessSparring
          v-if="isSpectatorView"
          :battle="battle"
          :selected-winner="sparring.winnerId"
          :sparring="sparring"
          disabled
          is-spectator
        />
      </div>

      <section
        v-if="sparring.status === 'need-tie-breaker' && !sparring.permissions.canAddExtra"
        class="need-tie-breaker"
      >
        <div class="content">
          <v-icon class="icon">
            {{ mdiClose }}
          </v-icon>
          <div class="text">Admin or host needs to add A tie breaker round</div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import api from '@/api'
import SparringBreadcrumbs from '@/components/SparringBreadcrumbs.vue'
import SparringRoundScore from '@/components/SparringRoundScore.vue'
import SparringRoundScoreEditor from '@/components/SparringRoundScoreEditor.vue'
import { mapGetters } from 'vuex'
import { mdiClose, mdiCrownOutline } from '@mdi/js'
import SparringAdminView from '@/pages/SparringPage/SparringAdminView.vue'
import ScorelessSparring from '@/components/Battle/Sparring/ScorelessSparring.vue'
import AudienceVoteView from '@/pages/SparringPage/AudienceVoteView.vue'
import { eventApi } from '@/enitites/event'

export default {
  name: 'SparringPage',
  components: {
    AudienceVoteView,
    ScorelessSparring,
    SparringAdminView,
    SparringRoundScoreEditor,
    SparringRoundScore,
    SparringBreadcrumbs,
  },
  data() {
    return {
      mdiClose,
      mdiCrownOutline,
      sparring: null,
      event: null,
      battle: null,
      roundsForJudgment: [],
      loadingPage: true,
      loading: false,
      isAudienceVoteMode: false,
      audienceVoteColor: '',
      mapToEdit: {},
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isSubmitScoreEnabled() {
      return !this.roundsForJudgment.filter((round) => !round.redPoints || !round.bluePoints).length
    },
    audienceWinnerColor() {
      if (
        !this.sparring.blue ||
        !this.sparring.red ||
        this.sparring.red.audiencePoints === this.sparring.blue.audiencePoints
      ) {
        return 'none'
      }

      return this.sparring.red.audiencePoints > this.sparring.blue.audiencePoints ? 'red' : 'blue'
    },
    isShowResetBtn() {
      return this.sparring.status === 'finished' && this.event.permissions.canEdit
    },
    isPointsEmpty() {
      return !this.sparring?.red?.totalPoints && !this.sparring?.blue?.totalPoints
    },
    isAdminOrganizerView() {
      return (
        this.isPointsEmpty &&
        (!!this.event.staff.admins.find((admin) => admin.id === this.getUserDB?.id) ||
          this.event.staff.organizer.id === this.getUserDB?.id)
      )
    },
    isSpectatorView() {
      return (
        this.isPointsEmpty &&
        this.sparring.status === 'finished' &&
        !this.event.staff.admins.find((admin) => admin.id === this.getUserDB?.id) &&
        this.event.staff.organizer.id !== this.getUserDB?.id
      )
    },
    isJudgeView() {
      return this.battle.judges.find((judge) => judge.id === this.getUserDB?.id)
    },
  },
  async mounted() {
    await this.fetchSparring()
    if (!this.sparring) return
    await Promise.all([this.fetchEvent(), this.fetchBattle()])
    if (this.sparring.permissions.canSetScore) this.setRoundsForJudgment()
    if (this.sparring.permissions.canSetAudienceScore) this.isAudienceVoteMode = true

    this.loadingPage = false
  },
  methods: {
    async submitScore() {
      await this.fetchSparring()

      this.mapToEdit = {}
      this.roundsForJudgment = []
      this.setRoundsForJudgment()
    },
    getRoundName(roundNumber) {
      const isExtraRound = roundNumber > this.sparring.rounds.length - this.sparring.extraRounds
      return isExtraRound ? 'Tie Break' : `Round ${roundNumber}`
    },
    async addExtraRound() {
      this.loading = true
      const updatedSparring = await api.sparrings.addExtraRound(this.sparring.sparringId)
      if (!updatedSparring) {
        this.loading = false
        return
      }

      this.sparring = updatedSparring

      if (this.sparring.permissions.canSetScore) {
        this.roundsForJudgment = []
        this.setRoundsForJudgment()
      }

      this.loading = false
    },
    async savePoints(round) {
      this.loading = true
      await api.sparrings.setSparringScore(this.sparring.sparringId, [round])
      this.loading = false
    },
    async finish() {
      this.loading = true
      const updatedSparring = await api.sparrings.finish(this.sparring.sparringId)
      if (updatedSparring) {
        this.sparring = updatedSparring
      }

      this.loading = false
    },
    handleAudienceVote(updatedSparring) {
      this.sparring = updatedSparring
      this.isAudienceVoteMode = false
    },
    getRoundJudgment(round) {
      return this.roundsForJudgment.find((r) => r.round === round)
    },
    setForEdit(index, round) {
      this.mapToEdit[index] = round
      this.setRoundsForJudgment()
    },
    setRoundsForJudgment() {
      this.sparring.rounds.forEach((round, index) => {
        const currentJudgment = round.scores.find((judge) => judge.id === this.getUserDB.id)
        if (currentJudgment && !this.mapToEdit[index]) return

        this.roundsForJudgment.push({
          round: index + 1,
          redPoints: (currentJudgment && currentJudgment.redPoints) || 0,
          bluePoints: (currentJudgment && currentJudgment.bluePoints) || 0,
        })
      })
    },
    async fetchSparring() {
      const sparring = await api.sparrings.getSparring(this.$route.params.id)
      if (!sparring) return
      this.sparring = sparring
    },
    async fetchBattle() {
      const battle = await api.battles.getBattle(this.sparring.battleId)
      if (battle) this.battle = battle
    },
    async fetchEvent() {
      const event = await eventApi.getEvent(this.sparring.eventId)
      if (event) this.event = event
    },
    isEnabledToJudgment(roundNumber) {
      const index = this.roundsForJudgment.findIndex((round) => round.round === roundNumber)
      if (!index || index === -1 || this.mapToEdit[roundNumber - 1]) return true

      const previousRound = this.roundsForJudgment[index - 1]
      return !!(previousRound && previousRound.redPoints && previousRound.bluePoints)
    },
    async reset() {
      const sparring = await api.sparrings.reset(this.sparring.sparringId)
      if (!sparring) return
      this.sparring = sparring
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.sparring {
  @include page-with-content;

  .wrapper {
    padding: 12px;
  }
}

.icon-gold {
  margin-bottom: 4px;
  color: var(--color-gold-900) !important;

  &.small {
    @include font-h3;
  }
}

.sparring-header,
.audience-mode .participants,
.total-score,
.audience-votes {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;
}

.sparring-header {
  margin-bottom: 20px;

  .participant {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    font-weight: var(--font-weight-bold);
    text-align: center;
  }
}

.rounds {
  .round {
    padding: 24px 0;
  }

  .round:not(:last-child) {
    border-bottom: 2px solid var(--color-grey-800);
  }
}

.total-score {
  margin-bottom: 20px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-grey-500);

  .name {
    text-align: center;
  }

  .participant-total {
    font-weight: var(--font-weight-bold);
    text-align: center;
  }
}

.audience-votes {
  h2 {
    @include font-h5;

    grid-column: 1 / span 3;
    margin-bottom: 10px;
    text-align: center;
  }

  .score {
    @include font-body2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 60px;
    color: var(--color-grey-300);

    &.winner {
      color: var(--color-gold-900);
      font-weight: var(--font-weight-bold);
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 40px;
}

.need-tie-breaker {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: var(--color-dark-800);

  .content {
    width: 60%;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    text-align: center;
    text-transform: uppercase;

    .icon {
      margin-bottom: 12px;
      color: var(--color-white);
    }
  }
}

.audience-mode-title {
  @include font-h3;

  display: block;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  text-align: center;
}
</style>
