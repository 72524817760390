<template>
  <BKLoader v-if="!event" />
  <section
    v-else
    class="event"
  >
    <!-- REMOVING -->

    <div class="event-container">
      <div class="details">
        <div class="background">
          <v-img
            :src="getImageById(event.image, 800, 800, 'event')"
            height="100%"
            width="100%"
          />
          <div class="gradient" />
        </div>
        <div
          :style="`background-image: url(${getImageById(event.image, $vuetify.breakpoint.mdAndUp ? 1000 : 300, $vuetify.breakpoint.mdAndUp ? 1000 : 300, 'event')})`"
          class="image"
        >
          <!-- <div class="image-background" /> -->
        </div>

        <EventSidebar
          :event.sync="event"
          @reload:event="initEvent"
        />
      </div>
      <div class="information">
        <div class="desc">
          <h2
            v-if="event.battles && event.battles.length"
            class="information-title"
          >
            Battles
          </h2>
          <!-- Battles container -->
          <EventBattles :event="event" />

          <h2
            v-if="event.description"
            class="information-title"
          >
            Description
          </h2>
          <!--          eslint-disable-next-line-->
          <p
            class="information-description"
            v-html="instance?.proxy?.$sanitize(event.description)"
          />
          <MediaPanel
            :can-edit="event.permissions.canEdit"
            :event-id="event.id"
            :org-id="event.orgId"
          />
        </div>
        <EventStaff :event="event" />
      </div>
      <BKModal
        :is-show.sync="isShowModal"
        border-width="large"
        class="modal"
        modal-width="500px"
        @update:isShow="onModalClose"
      >
        <template #title>
          <div class="modal-title">
            <BKSvgIcon
              color="var(--color-default-orange)"
              height="60px"
              name="email"
              width="60px"
            />
          </div>
        </template>

        <h3 class="subtitle">We’ve emailed you some help</h3>
        <p class="description">We’ve emailed you a QR code for your event and a “How To Guide” for Break Konnect.</p>
      </BKModal>
    </div>
  </section>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { MediaPanel } from '@/widgets/MediaPanel'
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useImage } from '@/shared/composables/image'
import { eventApi, EventDB } from '@/enitites/event'
import { BKModal } from '@/shared/ui/modals'
import BKSvgIcon from '@/components/AppShared/BKSvgIcon.vue'
import EventBattles from './EventBattles.vue'
import EventStaff from './EventStaff.vue'
import EventSidebar from './EventSidebar.vue'

const instance = getCurrentInstance()
const { getImageById } = useImage()
const route = useRoute()
const event = ref<EventDB>()
const eventId = computed(() => route.params.id)

const isShowModal = ref(store.getters.getShowEventInfoModal)

function onModalClose(value: boolean) {
  if (!value) {
    store.dispatch('setShowEventInfoModal', false)
  }
}

async function initEvent() {
  event.value = undefined
  const fetchedEvent = await eventApi.getEvent(Number(eventId.value))
  if (!fetchedEvent) {
    await store.dispatch('setIsNotFound', !fetchedEvent)
    return
  }

  event.value = fetchedEvent
}

watch(eventId, () => {
  initEvent()
})

onMounted(() => {
  initEvent()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.bk-modal {
  .modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .subtitle {
    @include font-h3;

    margin-bottom: 12px;
    color: var(--color-dark-800);
  }

  .description {
    @include font-body1;

    margin-bottom: 0;
    color: var(--color-dark-800);
  }
}

.event {
  position: relative;
  padding: 80px 0 48px;
  background: var(--color-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 920px) {
    background: white !important;
  }

  .background {
    position: absolute;
    top: -40px;
    width: 120%;
    height: 120%;
    filter: blur(60px);
  }

  .gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgb(0 0 0 / 90%), rgb(0 0 0 / 90%));
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }

  .alert {
    position: absolute;
    z-index: 1;
    width: 100%;
  }

  .event-container {
    width: 100%;
    height: 100%;
    min-height: 1000px;
    margin: 10px auto;
    background-color: var(--color-white);

    @media (max-width: 920px) {
      margin: 0 auto;
    }
  }

  .details {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 60px 0;
    overflow: hidden;

    @media (max-width: 920px) {
      flex-wrap: wrap;
      padding-bottom: 30px;
    }

    .image-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgb(0 0 0 / 62%) 0%, rgb(0 0 0 / 35%) 8%, rgb(255 255 255 / 0%) 20%);
    }

    .image {
      position: relative;
      width: 100%;
      max-width: 525px;
      height: 525px;
      background-position: center;
      background-size: cover;

      @media (max-width: 600px) {
        width: 270px;
        height: 270px;
        min-height: 100%;
      }
    }
  }

  .information {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 780px;
    background: white;

    @media (max-width: 920px) {
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
    }

    .information-title {
      @include font-h4;

      margin-bottom: 8px;
    }
  }

  .desc {
    width: 100%;
    max-width: 525px;
    padding: 24px 0;

    @media (max-width: 920px) {
      max-width: unset;
    }
  }

  .battles,
  .information-title + p {
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss">
.information-description {
  ul,
  li {
    list-style-type: inherit;
  }

  p {
    margin: 0 !important;
  }
}
</style>
