<script lang="ts" setup>
import { ref } from 'vue'
import { battleApi } from '@/enitites/battle'

const loadingCSV = ref(false)

interface DownloadCSVButtonProps {
  battleId: number
}

const props = defineProps<DownloadCSVButtonProps>()

async function downloadSCV() {
  loadingCSV.value = true
  await battleApi.downloadCSV(props.battleId)
  loadingCSV.value = false
}
</script>

<template>
  <BKButton
    class="csv-btn"
    white-text
    @click="downloadSCV"
  >
    Download CSV
  </BKButton>
</template>
