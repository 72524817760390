<template>
  <v-dialog
    v-model="isShow"
    :max-width="modalWidth"
    content-class="bk-modal"
    overlay-color="var(--color-black)"
    overlay-opacity="0.9"
    transition="dialog-bottom-transition"
    @click:outside="emit('update:isShow', false)"
    @keydown.esc="emit('update:isShow', false)"
  >
    <div
      :style="modalWrapperCssStyles"
      class="bk-modal-wrapper"
    >
      <div class="title-wrapper">
        <slot name="title" />
      </div>

      <div
        :style="contentCssStyles"
        class="content-wrapper"
      >
        <slot />
      </div>

      <div class="actions-wrapper">
        <slot name="actions" />
      </div>

      <button
        class="close-button"
        type="button"
        @click="emit('update:isShow', false)"
      >
        <v-icon
          color="var(--color-white)"
          size="24"
        >
          {{ mdiClose }}
        </v-icon>
        Close
      </button>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import { mdiClose } from '@mdi/js'
import { computed, defineEmits, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    isShow: boolean
    modalWidth?: string | number
    borderWidth?: 'medium' | 'none' | 'large'
    backgroundColor?: string
  }>(),
  {
    isShow: false,
    modalWidth: 630,
    borderWidth: 'medium',
    backgroundColor: '#fff',
  }
)

const emit = defineEmits<{
  (e: 'update:isShow', value: boolean): void
}>()

const paddingMap = {
  medium: {
    modalWrapper: '20px 0',
    content: '0 20px',
  },
  none: {
    modalWrapper: '0',
    content: '0',
  },
  default: {
    modalWrapper: '0 20px',
    content: '20px 0',
  },
  large: {
    modalWrapper: '40px 0',
    content: '0 40px',
  },
}

const getPadding = (borderWidth: 'medium' | 'none', modalWrapper: boolean) => {
  const type = modalWrapper ? 'modalWrapper' : 'content'
  return paddingMap[borderWidth]?.[type] || paddingMap.default[type]
}

const modalWrapperCssStyles = computed(() => ({
  padding: getPadding(props.borderWidth, true),
}))

const contentCssStyles = computed(() => ({
  padding: getPadding(props.borderWidth, false),
}))

const updateScroll = (isShow: boolean) => {
  const method = isShow ? 'add' : 'remove'
  document.documentElement.classList[method]('no-scroll')
}

watch(() => props.isShow, updateScroll)
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

::v-deep .bk-modal {
  height: calc(100vh - 90px);
  margin: auto 0 0;
  overflow: visible;

  @media screen and (min-width: 630px) {
    height: auto;
    max-height: 86vh !important;
    margin: 24px;
  }
}

.bk-modal-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  color: var(--color-dark-800);
  background-color: var(--color-white);
  border-radius: 20px 20px 0 0;

  .close-button {
    position: absolute;
    top: -56px;
    right: 20px;
    display: flex;
    align-items: center;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);

    &:focus-visible {
      border-radius: var(--border-radius-rounded);
      outline: 1px solid var(--color-white);
    }

    @media screen and (min-width: 630px) {
      top: -40px;
      right: 0;
    }
  }

  .title-wrapper {
    @include font-h4;

    flex: 0 0 auto;
    padding: 0 20px 20px;
    font-weight: var(--font-weight-bold);

    &:empty {
      display: none;
    }
  }

  .content-wrapper {
    flex: 0 1 auto;
    height: inherit;
    overflow: hidden auto;
    overscroll-behavior: none;
  }

  .actions-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    align-items: stretch;
    margin-top: auto;
    padding: 20px 20px 0;
    border-top: 1px solid var(--color-grey-600);

    &:empty {
      display: none;
    }

    @media screen and (min-width: 630px) {
      flex-direction: row;
      margin: 0;

      &::v-deep > * {
        flex: 1 1 auto;
      }
    }
  }

  @media screen and (min-width: 630px) {
    max-height: 86vh;
    border-radius: 20px;
  }
}
</style>
