import { render, staticRenderFns } from "./EventPage.vue?vue&type=template&id=697e2cea&scoped=true"
import script from "./EventPage.vue?vue&type=script&lang=ts&setup=true"
export * from "./EventPage.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./EventPage.vue?vue&type=style&index=0&id=697e2cea&prod&lang=scss&scoped=true"
import style1 from "./EventPage.vue?vue&type=style&index=1&id=697e2cea&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697e2cea",
  null
  
)

export default component.exports