<script lang="ts" setup>
import { ref } from 'vue'
import api from '@/api'
import { ISparring } from '@/types/sparring'

interface IProps {
  sparring: ISparring
}

const props = defineProps<IProps>()
const emit = defineEmits<{
  (e: 'update:sparring', value: ISparring): void
}>()

const audienceVoteColor = ref<'blue' | 'red'>()
const loading = ref(false)

async function submitAudienceScore() {
  loading.value = true

  const updatedSparring = await api.sparrings.setAudienceScore(props.sparring.sparringId, audienceVoteColor.value)
  loading.value = false
  if (!updatedSparring) return
  emit('update:sparring', updatedSparring)
}
</script>

<template>
  <section class="audience-vote-view">
    <div class="wrapper">
      <div>
        <p class="name">
          {{ sparring.red.name }}
        </p>
        <button
          :class="['vote-button', { active: audienceVoteColor === 'red' }]"
          type="button"
          @click="audienceVoteColor = 'red'"
        >
          Vote
        </button>
      </div>

      <div />
      <div>
        <p class="name">
          {{ sparring.blue.name }}
        </p>
        <button
          :class="['vote-button', { active: audienceVoteColor === 'blue' }]"
          type="button"
          @click="audienceVoteColor = 'blue'"
        >
          Vote
        </button>
      </div>
    </div>

    <BKButton
      :disabled="!audienceVoteColor"
      :loading="loading"
      color="var(--color-blue-800)"
      white-text
      @click="submitAudienceScore"
    >
      Submit
    </BKButton>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.audience-vote-view {
  .audience-mode-title {
    @include font-h3;

    display: block;
    margin-bottom: 20px;
    font-weight: var(--font-weight-bold);
    text-align: center;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 0.4fr 1fr;
    margin-bottom: 20px;
  }

  .name {
    text-align: center;
  }

  .vote-button {
    width: 100%;
    padding: 30px 0;
    text-align: center;
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    cursor: pointer;
    transition:
      0.5s background-color,
      color;

    &.active {
      color: var(--color-white);
      background-color: var(--color-green);
    }
  }
}
</style>
