<script lang="ts" setup>
import { computed, toRefs, useAttrs } from 'vue'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'

const props = withDefaults(
  defineProps<{
    modelValue: boolean | boolean[] | string | string[]
    disabled?: boolean
    color?: string
    label: string
    hideDetails?: boolean | 'auto'
    checkedIcon?: string
    uncheckedIcon?: string
  }>(),
  {
    hideDetails: 'auto',
    color: 'var(--color-blue-800)',
    disabled: false,
    checkedIcon: mdiRadioboxMarked,
    uncheckedIcon: mdiRadioboxBlank,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean | boolean[] | string | string[]): void
  (e: 'input', value: boolean | boolean[] | string | string[]): void
  (e: 'change', value: boolean | boolean[] | string | string[]): void
}>()

const { disabled, color, hideDetails } = toRefs(props)
const attrs = useAttrs()

const value = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val)
    emit('input', val)
    emit('change', val)
  },
})
</script>

<template>
  <v-radio
    v-model="value"
    :color="color"
    :disabled="disabled"
    :hide-details="hideDetails"
    :label="label"
    :off-icon="uncheckedIcon"
    :on-icon="checkedIcon"
    class="radio-input"
    v-bind="attrs"
  >
    <template #label>
      <slot name="label">
        <slot />
      </slot>
    </template>
  </v-radio>
</template>

<style lang="scss">
.radio-input {
  .v-label {
    color: var(--color-dark-800);
  }
}
</style>
