// @ts-expect-error Need to rewrite later
import { callPrivateApi } from '@/api/apiHelper'
import { BattleDB } from '../model/types'

export const battleApi = {
  async addBattle(eventId: number): Promise<BattleDB> {
    return callPrivateApi<BattleDB>('post', `/events/${eventId}/add-battle`)
  },

  async downloadCSV(battleId: number): Promise<void> {
    try {
      const response = await callPrivateApi('get', `/battles/${battleId}/download`, {}, { responseType: 'blob' })

      const blob = new Blob([response], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `battle_${battleId}.csv`
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading battle data:', error)
    }
  },
}
